import "./App.css";
import Admin from "./Admin";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Screen from "./Screen";

function App() {


  return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Screen/>
          </Route>
          <Route exact path="/add">
            <Admin/>
          </Route>
        </Switch>

      </Router>
  );
}

export default App;
