import React from 'react';
import { useEffect, useState } from "react";

import {getDownloadURL, listAll} from "firebase/storage";
import {
    collection,
    getDocs,
    deleteDoc,
    doc,
    onSnapshot,
} from "firebase/firestore";
import {db} from "./firebase";
import styled from "styled-components";

const IndicatorWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  margin: 5px;
  transition: 750ms all ease-in-out;
`;

const Indicator = ({ currentSlide, amountSlides, nextSlide }) => {
    return (
        <IndicatorWrapper>
            {Array(amountSlides)
                .fill(1)
                .map((_, i) => (
                    <Dot
                        key={i}
                        isActive={currentSlide === i}
                        onClick={() => nextSlide(i)}
                    />
                ))}
        </IndicatorWrapper>
    );
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  position: relative;
`;

const Slide = styled.div`
  height: 100%;
  width: 100vw;
  flex-shrink: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgb(61, 61, 61);
  transition: 750ms all ease-in-out;
`;

const ChildrenWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
`;
const Screen = ({autoPlay = true,
                    autoPlayTime = 3000,
                    children,
                    ...props}) => {

    const [data, setData] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    function nextSlide(slideIndex = currentSlide + 1) {
        const newSlideIndex = slideIndex >= data.length ? 0 : slideIndex;

        setCurrentSlide(newSlideIndex);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            nextSlide();
            console.log("Duration : ",parseInt(data[currentSlide].duration))
        }, autoPlayTime);

        return () => clearTimeout(timer);
    }, [data,currentSlide]);

    useEffect(() => {
        const unsub = onSnapshot(
            collection(db, "screen"),
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({ id: doc.id, ...doc.data() });
                });
                setData(list);
            },
            (error) => {
                console.log(error);
            }
        );

        return () => {
            unsub();
        };
    }, []);

    console.log("DATA : ",data);
    return (
        // <div>
        //     {data.map((data) => {
        //         return <img src={data.file} key={data.id} />;
        //     })}
        // </div>

        <Wrapper {...props}>
            {data.map((imageUrl, index) => (
                <Slide
                    key={index}
                    style={{
                        backgroundImage: `url(${imageUrl.file})`,
                        marginLeft: index === 0 ? `-${currentSlide * 100}%` : undefined,
                    }}
                ></Slide>
            ))}
            <Gradient />
            <Indicator
                currentSlide={currentSlide}
                amountSlides={data.length}
                nextSlide={nextSlide}
            />
            <ChildrenWrapper>{children}</ChildrenWrapper>
        </Wrapper>
    );
};

export default Screen;
