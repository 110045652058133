import React, {useEffect, useState} from 'react';
import {getDownloadURL, listAll, ref, uploadBytes, uploadBytesResumable} from "firebase/storage";
import {db, storage} from "./firebase";
import {v4} from "uuid";
import {
    addDoc,
    collection,
    doc,
    serverTimestamp,
    setDoc,
} from "firebase/firestore";

const Admin = () => {

    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [file, setFile] = useState("");
    const [fileUrl, setFileUrl] = useState();
    const [per, setPer] = useState(null);
    const [duration, setDuration] = useState(null);

    const imagesListRef = ref(storage, "images/");

    const submitDetails = async () => {
        // if (imageUpload == null) return;
        // const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        // uploadBytes(imageRef, imageUpload).then((snapshot) => {
        //     getDownloadURL(snapshot.ref).then((url) => {
        //         setImageUrls((prev) => [...prev, url]);
        //     });
        // });
        await addDoc(collection(db, "screen"),{
            file: fileUrl,
            duration: duration
        });
    };

    useEffect(() => {
        const uploadFile = () => {
            const name = new Date().getTime() + file.name;

            console.log(name);
            const storageRef = ref(storage, file.name+v4());
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    setPer(progress);
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        case "running":
                            console.log("Upload is running");
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setFileUrl(downloadURL);
                    });
                }
            );
        };
        file && uploadFile();
        console.log("url : ", fileUrl);
    }, [file]);


    // useEffect(() => {
    //     listAll(imagesListRef).then((response) => {
    //         response.items.forEach((item) => {
    //             getDownloadURL(item).then((url) => {
    //                 setImageUrls((prev) => [...prev, url]);
    //             });
    //         });
    //     });
    // }, []);

    return (
        <div className="App">
            <input
                type="file"
                onChange={(event) => {
                    setFile(event.target.files[0]);
                }}
            />
            <input
                type="number"
                placeholder={"duration"}
                onChange={(event) => setDuration(event.target.value)}
            />
            <br/>
            <br/>
            <button disabled={per !== null && per < 100} onClick={submitDetails}>Submit</button>
            {/*{imageUrls.map((url) => {*/}
            {/*    return <img src={url} />;*/}
            {/*})}*/}
        </div>
    );
};

export default Admin;
